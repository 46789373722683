import { CherryProjectData } from '@metavrse-inc/metavrse-lib';
import { atom, Getter, Setter } from 'jotai';

import { isPreviewOpenAtom } from 'atoms/editor/preview';
import { generateProjectDataAtom } from 'atoms/editor/project';
import viewerHelpers from 'atoms/helpers/viewer/viewer.helpers';
import { viewerAtom } from 'atoms/viewer/viewer';

type Arguments = [Getter, Setter];

const setMethod = (...args: Arguments): void => {
  const [get] = args;

  const viewer = get(viewerAtom);
  const projectData = get(generateProjectDataAtom) as CherryProjectData;

  if (viewer) {
    const isPreview = get(isPreviewOpenAtom);
    viewerHelpers.loadScene(viewer, projectData, !isPreview, isPreview);
  }
};

export const loadSceneAtom = atom(null, setMethod);
