import { CherryGLVersion, ProjectData } from '@metavrse-inc/metavrse-lib';
import { add, differenceInMilliseconds, millisecondsToMinutes } from 'date-fns';
import { atom, Setter } from 'jotai';
import { atomWithReset, atomWithStorage, RESET } from 'jotai/utils';
import MagicPortal from 'magic-portal';

import {
  ASSET_TYPES_ALWAYS_GIT_ADD,
  ASSETS_FILENAME,
  ASSETS_FOLDER,
  AUTOSAVE_SHIFT_IN_SECONDS,
  AUTOSAVE_TOGGLE,
  CONFIGURATIONS_FILENAME,
  DEFAULT_AUTOSAVE_INTERVAL_IN_MS,
  EDITOR_ZOOM,
  ENTITIES_FILENAME,
  HTML_HUD_TREE_FILENAME,
  PROJECT_FILENAME,
  PUSH_WORKER_PATH,
  SCENES_FOLDER,
  SNACKBAR_FAILED_PROGRESS,
  TREE_FILENAME,
  WORLD_FILENAME,
} from 'configConstants';

import { LoadingStatus, StateTypes } from 'models';
import { ClickableEditorElements } from 'models/editor/clickableEditorElements.model';
import SnackbarType from 'models/snackbars/snackbarType';

import { writeAssetsJsonFile } from 'services/fileSystem/assets';
import { writeEntitiesFile } from 'services/fileSystem/entities';
import {
  checkDirectorySize,
  writeProjectFile,
} from 'services/fileSystem/project';
import { writeTreeFile } from 'services/fileSystem/tree';
import { writeWorldFile } from 'services/fileSystem/world';
import { fsp } from 'services/fs.service';
import {
  addFile,
  assetsFileNamesReducer,
  listAllFilesAndDirectories,
  removeFile,
  thumbnailsFileNamesReducer,
} from 'services/git';

import { getIncrementalId } from 'stores/editor';

import { assetsAtom, removedAssetKeysAtom } from 'atoms/assets/assets.atom';
import { projectAtom } from 'atoms/editor/project';
import { selectedSceneAtom } from 'atoms/editor/scenes';
import { worldAtom } from 'atoms/editor/world';
import { entitiesAtom } from 'atoms/entities/entities.atom';
import assetsHelpers from 'atoms/helpers/assets-helpers';
import {
  projectAtom as projectMetadataAtom,
  userRoleAtom,
} from 'atoms/manager/project.atom';
import { modalDataAtom } from 'atoms/modal';
import {
  setSnackbarAtom,
  updateProgressSnackbarAtom,
} from 'atoms/snackbars/snackbars.atom';
import { setStateAtom } from 'atoms/state';
import { treeAtom } from 'atoms/tree/tree.atom';
import { viewerAtom } from 'atoms/viewer/viewer';

import { configurationsTreeAtom } from '_configurations/atoms/configurations.atom';
import { writeConfigurationsFile } from '_configurations/services/configurations.service';

import { htmlHudTreeAtom } from '_html-hud/atoms/htmlHudTree.atom';
import { writeHTMLHudTreeFile } from '_html-hud/services/filesystem/HTMLHudTreeFS.service';

const { HEADER } = StateTypes;
const { LOADING, READY } = LoadingStatus;

export const autosaveToggle = atomWithStorage(AUTOSAVE_TOGGLE, false);
export const zoomAtom = atomWithStorage(EDITOR_ZOOM, '100%');
export const autosaveIntervalAtom = atomWithReset<
  ReturnType<typeof setInterval> | undefined
>(undefined);
export const startIntervalTimeAtom = atom<undefined | Date>(undefined);
export const clickedEditorElementAtom =
  atomWithReset<ClickableEditorElements | null>(null);

const saveSnackbarKey = Date.now();

const pushToGit = async (set: Setter, key: string) => {
  try {
    const projectSize = await checkDirectorySize('/');
    await set(commitAndPushAtom, {
      message: `Save: ${Date.now()}, scene: ${key}`,
      dir: '/',
      projectSize,
    });
  } catch (error) {
    set(setStateAtom([HEADER]), { value: READY });

    set(modalDataAtom, {
      key: 'exceededLimits',
      isOpen: true,
    });

    set(updateProgressSnackbarAtom, {
      snackbarKey: saveSnackbarKey,
      newTitle: 'Error',
      newMessage: `Project was not saved`,
      progress: SNACKBAR_FAILED_PROGRESS,
      newType: SnackbarType.ERROR,
      isVisible: true,
      frozen: true,
    });
  }
};

export const saveChangesAtom = atom(null, async (get, set) => {
  const updateProgress = (progressValue: number) => {
    set(updateProgressSnackbarAtom, {
      snackbarKey: saveSnackbarKey,
      progress: progressValue,
    });
  };

  const userRole = get(userRoleAtom);
  if (userRole === 'VIEWER') {
    return;
  }

  set(setStateAtom([HEADER]), { value: LOADING });
  set(setSnackbarAtom, {
    title: 'Saving',
    body: 'Save in progress...',
    type: SnackbarType.INFO,
    key: saveSnackbarKey,
    isProgress: true,
    progressValue: 0,
    isVisible: true,
  });

  const key = get(selectedSceneAtom);
  const project = get(projectAtom);
  const tree = get(treeAtom);
  const htmlHudTree = get(htmlHudTreeAtom);
  const configurationsTree = get(configurationsTreeAtom);
  const assets = get(assetsAtom);
  const entities = get(entitiesAtom);
  const world = get(worldAtom);
  const incrementalId = Number(getIncrementalId());
  const viewer = get(viewerAtom);
  const removedAssetKeys = get(removedAssetKeysAtom);

  const repositoryFilesAndDirectories = await listAllFilesAndDirectories();
  const repositoryAssets = repositoryFilesAndDirectories.reduce(
    assetsFileNamesReducer,
    []
  );
  const repositoryThumbnails = repositoryFilesAndDirectories.reduce(
    thumbnailsFileNamesReducer,
    []
  );
  const hasAssetsFolder = repositoryFilesAndDirectories.includes(ASSETS_FOLDER);

  const updatedProject: ProjectData = {
    ...project,
    incrementalId,
    updateDate: new Date().toISOString(),
    version: CherryGLVersion,
  };

  updateProgress(10);

  await writeTreeFile(key, tree);
  await writeEntitiesFile(key, entities);
  await writeWorldFile(key, world);
  await writeHTMLHudTreeFile(key, htmlHudTree);
  await writeConfigurationsFile(key, configurationsTree);

  updateProgress(30);

  await addFile(`${SCENES_FOLDER}/${key}/${TREE_FILENAME}`);
  await addFile(`${SCENES_FOLDER}/${key}/${ENTITIES_FILENAME}`);
  await addFile(`${SCENES_FOLDER}/${key}/${WORLD_FILENAME}`);
  await addFile(`${SCENES_FOLDER}/${key}/${HTML_HUD_TREE_FILENAME}`);
  await addFile(`${SCENES_FOLDER}/${key}/${CONFIGURATIONS_FILENAME}`);

  updateProgress(40);

  await writeProjectFile(updatedProject);

  await addFile(PROJECT_FILENAME);

  const assetsDir = await fsp.readdir(`/${ASSETS_FOLDER}`);
  const flattenedAssets = assetsHelpers.flattenAssets(assets);
  const assetsDirWithMetadata = assetsDir.map((key) => {
    const asset = flattenedAssets.find(({ key: assetKey }) => assetKey === key);

    return {
      key,
      ...asset,
    };
  });

  updateProgress(50);

  await Promise.all(
    removedAssetKeys.map(async (assetKey) => {
      await removeFile(`${ASSETS_FOLDER}/${assetKey}`);
    })
  );
  set(removedAssetKeysAtom, RESET);

  updateProgress(60);

  await Promise.all(
    assetsDirWithMetadata.map(async (asset) => {
      const { key, type } = asset;
      if (
        !ASSET_TYPES_ALWAYS_GIT_ADD.includes(type) &&
        repositoryAssets.includes(key)
      ) {
        const ifFileSizesAreSame =
          await assetsHelpers.checkIfFilesSizeBetweenGitRepositoryAndViewerAreSame(
            viewer,
            ASSETS_FOLDER,
            key
          );

        if (ifFileSizesAreSame) return;
      }

      await addFile(`${ASSETS_FOLDER}/${key}`);
    })
  );

  updateProgress(75);

  await writeAssetsJsonFile(assets);

  if (!hasAssetsFolder) {
    // Possibly unnecessary at all
    await addFile(ASSETS_FOLDER);
  }

  await addFile(ASSETS_FILENAME);

  updateProgress(80);

  set(projectAtom, updatedProject);
  set(setStateAtom([HEADER]), { value: READY });

  await pushToGit(set, key);

  set(updateProgressSnackbarAtom, {
    snackbarKey: saveSnackbarKey,
    progress: 100,
    newTitle: 'Project saved!',
    newMessage: 'Project saved successfully',
    newType: SnackbarType.SUCCESS,
  });
});

type CommitAndPush = {
  message: string;
  dir: string;
  projectSize: number;
};

const commitAndPushAtom = atom(
  null,
  async (get, set, { message, dir, projectSize }: CommitAndPush) => {
    const pushWorker = new Worker(PUSH_WORKER_PATH);
    const portal = new MagicPortal(pushWorker);
    const project = get(projectMetadataAtom);

    const mainThreadMethods = {
      //TODO: [MET-1364] Add type when BE will be able to return progress
      async progress(evt: any) {
        console.log('progress', evt);
        return;
      },
    };

    portal.set('mainThread', mainThreadMethods, {
      void: ['progress'],
    });

    const workerThread = await portal.get('workerThread');

    const projectId = project?.id;
    const repoUrl = `${process.env.API_BASE_URL}/projects/${projectId}/repo`;

    await workerThread.push({ repoUrl, projectId, message, dir, projectSize });
  }
);

interface IStartAutosaveInterval {
  intervalTime: number;
  hasBeenShifted: boolean;
}

export const startAutosaveIntervalAtom = atom(
  null,
  (get, set, { intervalTime, hasBeenShifted }: IStartAutosaveInterval) => {
    set(
      autosaveIntervalAtom,
      setInterval(async () => {
        set(startIntervalTimeAtom, new Date());
        await set(saveChangesAtom);

        if (hasBeenShifted) {
          set(stopAutosaveIntervalAtom);

          // atom can't do set on itself, that's why we need separate atom
          set(restartAutosaveIntervalAtom, {
            intervalTime: DEFAULT_AUTOSAVE_INTERVAL_IN_MS,
            hasBeenShifted: false,
          });
        }
      }, intervalTime)
    );
  }
);

export const restartAutosaveIntervalAtom = atom(
  null,
  (get, set, { intervalTime, hasBeenShifted }: IStartAutosaveInterval) => {
    set(startAutosaveIntervalAtom, {
      intervalTime,
      hasBeenShifted,
    });
  }
);

export const stopAutosaveIntervalAtom = atom(null, (get, set) => {
  const currentInterval = get(autosaveIntervalAtom);

  if (currentInterval) {
    clearInterval(currentInterval);
    set(autosaveIntervalAtom, RESET);
  }
});

export const shiftAutosaveIntervalAtom = atom(null, (get, set) => {
  const startIntervalTime = get(startIntervalTimeAtom);

  if (startIntervalTime) {
    const expectedAutosaveAt = add(startIntervalTime, {
      minutes: millisecondsToMinutes(DEFAULT_AUTOSAVE_INTERVAL_IN_MS),
    });

    const updatedAutosaveAt = add(expectedAutosaveAt, {
      seconds: AUTOSAVE_SHIFT_IN_SECONDS,
    });

    set(stopAutosaveIntervalAtom);

    const newAutosaveTimeInMs = differenceInMilliseconds(
      updatedAutosaveAt,
      new Date()
    );

    set(startAutosaveIntervalAtom, {
      intervalTime: newAutosaveTimeInMs,
      hasBeenShifted: true,
    });
  }
});
